import React from "react";
import "./GetInTouch.css";

import { Link } from "react-router-dom";

const GetInTouch = () => {
  return (
    <div className="begin-with flex flex-col justify-center items-center">
      <div className="child-getInTouch">
        <div className="">
          <h2 className="text-2xl font-semibold text-center text-white">
            Begin your wealth journey with prospr
          </h2>
        </div>
        <Link to={"/join-waitlist"} className="flex justify-center ">
          <button className="btn-join-get-in-touch rounded-full">
            Join the waitlist!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GetInTouch;
