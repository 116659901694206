import React, { useEffect, useState } from "react";
import Menu from "../../../Assets/menu.svg";
import Close from "../../../Assets/close.svg";
import Spring1 from "../../../Assets/springNavbar2.svg";
import Spring2 from "../../../Assets/springNavbar.svg";
import NavTitle from "../../../Assets/NavTitle.svg";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [bgColor, setBgColor] = useState("bg-transparent");
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setBgColor("backdrop-blur bg-[#0060FF]/70"); // Tailwind CSS class for red background color
      } else {
        setBgColor("bg-transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <nav
    //   className={`w-[100%] ${bgColor} flex justify-between px-5 py-6 fixed top-0 transition-colors duration-300 z-30`}
    // >
    //   <img className="" src={NavTitle} alt="Prospr" />
    //   <span onClick={() => setShowNavbar(!showNavbar)}>
    //     <img className="" alt="menu-icon" src={Menu} />
    //   </span>

    //
    // </nav>
    <nav
      className={`w-[100%] h-[70px]  ${bgColor} fixed top-0 transition-colors duration-300 z-[2]`}
    >
      <div className="w-[100%] h-[100%] flex justify-between items-center px-5">
        <Link to={"/"} className="flex justify-cente1">
          <span>
            <img className="" alt="menu-icon" src={NavTitle} />
          </span>
        </Link>
        {/* <span onClick={() => setShowNavbar(!showNavbar)}>
          <img className="menu-icons" alt="menu-icon" src={Menu} />
        </span> */}
        <Link to={"/join-waitlist"} className="btn-join-navbar  rounded-full ">
          Join waitlist!
        </Link>
      </div>
      {/* <div
        className={`bg-of-navbar-res h-[100vh] w-full transition-all duration-300		 ${
          showNavbar ? "left-0" : "-left-[100%]"
        }`}
      >
        <div className="h-[100%] navbar-res px-5">
          <div className="h-[80px] flex justify-end items-center z-[999]">
            <img
              onClick={() => {
                setShowNavbar(!showNavbar);
              }}
              className="menu-icons"
              alt="menu-icons"
              src={Close}
            />
          </div>
          <ul className="nav-list">
            <li onClick={() => setShowNavbar(false)}>Values</li>
            <li onClick={() => setShowNavbar(false)}>How it works?</li>
            <li onClick={() => setShowNavbar(false)}>FAQs</li>
            <li onClick={() => setShowNavbar(false)}>Blogs</li>
          </ul>
          <img
            className="absolute top-0 right-0"
            alt="menu-icon"
            src={Spring1}
          />
          <img
            className="absolute bottom-0 left-0"
            alt="menu-icon"
            src={Spring2}
          />
        </div>
      </div> */}
    </nav>
  );
};

export default Navbar;
