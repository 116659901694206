import React, { useEffect, useRef } from "react";

import Chart from "chart.js/auto";

const ChartCalulation = ({
  M,
  selectedAmount,
  duration,
  riskSelection,
  currency,
}) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  console.log(selectedAmount, duration * 12, riskSelection / 100);
  const selectedAmountData = selectedAmount;
  const durationData = duration * 12;
  const riskSelectionData = Number(riskSelection) / 100;
  let result = selectedAmountData * durationData;

  const calculateM = (P, n, i) => {
    // M = P * ({[1+i]^{12}-1}/i)*(1+i)
    const part1 = [1 + i] ** n - 1;
    const part2 = (part1 / i) * (1 + i);
    return P * part2;
  };

  const handleCalculateButton = (d) => {
    let P = selectedAmount;
    let n = d * 12;
    let rateOfInterest = Number(riskSelection) / 100;

    let i = rateOfInterest / 12;

    const M = calculateM(P, n, i);

    return Math.round(M);
  };

  //  generateLabels /
  const generateLabels = (duration) => {
    let labels = [];
    for (let i = 0; i <= duration; i++) {
      labels.push(`${i}yr`);
    }

    return labels;
  };

  const generateValues = () => {
    let labels = [0];
    for (let i = 1; i <= duration; i++) {
      const temp = handleCalculateButton(i);
      labels.push(temp);
    }
    return labels;
  };

  const generateValues2 = () => {
    let labels = [0];
    for (let i = 1; i <= duration; i++) {
      const temp = selectedAmount * 12 * i;
      labels.push(temp);
    }
    return labels;
  };



  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: "line",
      data: {
        labels: generateLabels(duration),
        datasets: [
          {
            label: "Invested Amount",
            data: generateValues2(),
            borderColor: "#b2b2b2",
            borderWidth: 2,
            pointBackgroundColor: "#b2b2b2",
            backgroundColor: [
              "rgba(134, 239, 172, 0.1)",
              "rgba(136, 163, 146, 0.3)",
            ],
            fill: true,

            tension: 0.5,
          },
          {
            label: "Expected Returns",
            data: generateValues(),
            borderColor: "blue",
            borderWidth: 1,
            pointBackgroundColor: "blue",
            backgroundColor: ["rgba(0, 96, 255, 1)", "rgba(92, 107, 255, 0.1)"],
            fill: true,
            tension: 0.5,
          },
        ],
      },
      options: {
        responsive: true,

        plugins: {
          legend: {
            display: false,
          },

          title: {
            display: false,
            text: "Stacked Line Chart with Years",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Year",
            },
            grid: {
              display: false,
            },
          },
          y: {
            position: "right",
            title: {
              display: false,
              text: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value, index, values) {
                // Convert value to thousands (K)
                let valueInK = value / 1000;
                return valueInK + "K";
              },
            },
          },
        },
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [selectedAmount, duration, riskSelection]);

  return (
    <div className="mt-10">
      <div className="w-[100%]  relative border-2 border-white">
        <canvas
          className="mt-[60px] px-2"
          ref={chartRef}
          style={{ width: "100%" }}
        ></canvas>
        <div className="max-w-[300px] w-[100%]  absolute top-0 left-0 ">
          <div className="">
            <h2 className="text-[15px] text-[#6B7280] ">
              Investment of&nbsp;
              <span className="text-xs text-[#000] font-semibold uppercase">
                {currency} {result.toLocaleString("en-IN")}
              </span>{" "}
              could grow to
            </h2>
            <h2 className="text-[26px] font-bold uppercase">
              {currency} {M}
            </h2>
            <h2 className="text-[15px] text-[#6B7280] ">
              in {duration} years at {riskSelection}% returns p.a*
            </h2>
          </div>
        </div>
      </div>

      <h2 className="text-[14px] text-[#6B7280] mt-3 ">
        *basis 3 years historical return. Investment in securities market are
        subject to market risks. Read all the related documents carefully before
        investing. The charts illustrate advantages of investing and
        compounding.
      </h2>
    </div>
  );
};

export default ChartCalulation;
