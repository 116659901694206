import React from "react";
import "./CalculationTable.css";
import SBI from "../../../Assets/SBITABlE.svg";
import SandP from "../../../Assets/S&P.svg";
import USFlag from "../../../Assets/US.webp";

import IndianFlag from "../../../Assets/India.svg"

const CalculationTable = ({ currency, selectedAmount }) => {
  return (
    <div className="table-investent">
      <div className="bg-[#F9FAFB] rounded-md shadow-md ">
        <div className="header flex border-b-2">
          <div className="w-[50%] flex justify-center items-center border-r-2">
            <h1 className="font-semibold text-center text-[0.9rem]">
              Fund Name
            </h1>
          </div>
          <div className="w-[50%]">
            <div>
              <h1 className="font-semibold text-center py-3 border-b-2 text-[0.9rem]">
                Returns
              </h1>
              <div className="flex justify-between items-center">
                <h1 className="w-[100%] py-3 text-center font-semibold border-r-2 text-heading">
                  1Yr
                </h1>
                <h1 className="w-[100%] py-3 text-center font-semibold border-r-2 text-heading">
                  2Yr
                </h1>
                <h1 className="w-[100%] py-3 text-center font-semibold text-heading">
                  3Yr
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="flex border-b-2 main-hover-design">
          <div className="w-[50%] flex flex-col justify-center border-r-2">
            <div className="w-[50px] h-[20px]">
              <img src={SBI} alt="sbi" className="w-[100%] h-[100%]" />
            </div>
            <h1 className="ps-2 hover-text flex gap-1">
              <img
                src={IndianFlag}
                alt="india"
                className="w-[18px] h-[18px] mt-[2px]"
              />
              SBI Fixed Deposit
            </h1>
          </div>
          <div className="w-[50%]">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="w-[100%] py-6 text-center border-r-2 text-percentage">
                  6.8%
                </h1>
                <h1 className="w-[100%] py-6 text-center border-r-2 text-percentage">
                  7.0%
                </h1>
                <h1 className="w-[100%] py-6 text-center text-percentage ">
                  6.5%
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  border-b-2 main-hover-design">
          <div className="w-[50%] flex flex-col  justify-center  border-r-2 ">
            <div className="w-[50px] h-[20px]">
              <img src={SBI} alt="sbi" className="w-[100%] h-[100%]" />
            </div>
            <h1 className="ps-2 hover-text flex gap-1 font-semibold">
              <img
                src={IndianFlag}
                alt="india"
                className="w-[18px] h-[18px] mt-[2px]"
              />
              SBI Nifty Index Fund
            </h1>
          </div>
          <div className="w-[50%]">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="w-[100%] py-6  font-semibold  text-center border-r-2 text-percentage">
                  26.0%
                </h1>
                <h1 className="w-[100%] py-6 font-semibold  text-center border-r-2 text-percentage">
                  15.9%
                </h1>
                <h1 className="w-[100%] py-6 font-semibold  text-center text-percentage">
                  15.8%
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="flex main-hover-design">
          <div className="w-[50%] flex flex-col  justify-center  border-r-2">
            <div className="w-[50px] h-[20px]">
              <img src={SandP} alt="sbi" className="w-[100%] h-[100%]" />
            </div>
            <h1 className="ps-2 hover-text flex gap-1 ">
            <img
                src={USFlag}
                alt="india"
                className="w-[18px] h-[10px] mt-[5px]"
              />
              S&P 500 Index Fund</h1>
          </div>
          <div className="w-[50%]">
            <div>
              <div className="flex justify-between items-center">
                <h1 className="w-[100%] py-6 text-center border-r-2 text-percentage">
                  24.2%
                </h1>
                <h1 className="w-[100%] py-6 text-center border-r-2 text-percentage">
                  8.4%
                </h1>
                <h1 className="w-[100%] py-6 text-center  text-percentage">
                  13.2%
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold text-center tracking-wide">
          Why invest in SBI Nifty 50?
        </h2>
        <p className="text-[#4B5563] font-semibold leading-relaxed tracking-wide mt-7">
          SBI Nifty Index Fund is an Exchange Traded Fund managed by SBI Mutual
          Fund, a subsidiary of State Bank of India.
        </p>
        <p className="text-[#4B5563] font-semibold leading-relaxed tracking-wide mt-5">
          {`
          SBI Mutual Fund, regulated by Securities Exchange Board of India
          ("SEBI"), manages assets of more > Rs. 7 lakh crore.
         `}
        </p>
      </div>
    </div>
  );
};

export default CalculationTable;
