import React, { useState } from "react";
import "./JoinWaitListForm.css";
import Save from "../../Assets/joinSave.svg";
import Prosper from "../../Assets/joinprospr.svg";
import Line from "../../Assets/joinLine.svg";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";

import IndiaFlag from "../../Assets/India.svg";
import USFlag from "../../Assets/US.webp";
import BritainFlag from "../../Assets/Britain.webp";
import SingaporeFlag from "../../Assets/Singapore.png";
import UAEFlag from "../../Assets/UAE.webp";
import SubmitSuccess from "./SubmitSuccess/SubmitSuccess";
import { Button, Snackbar } from "@mui/joy";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const JoinWaitListForm = () => {
  const [value, setValue] = useState();

  const phoneNumberSend = "+919790742684";
  const message = "Hi";

  const handleClick = (e) => {
    e.preventDefault();
    const whatsappURL = `https://wa.me/${phoneNumberSend}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const countryOptions = [
    { label: "uae", value: "+971", flagImg: UAEFlag },
    { label: "india", value: "+91", flagImg: IndiaFlag },
    { label: "singapore", value: "+65", flagImg: SingaporeFlag },
    { label: "us", value: "+1", flagImg: USFlag },
    { label: "britain", value: "+44", flagImg: BritainFlag },
  ];
  const defaultValue = countryOptions.find((option) => option.value === "+91");

  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [email, setEmail] = useState("");
  const [panKYC, setPanKYC] = useState("");

  // snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // show success page
  const [successStatus, setSuccessStatus] = useState(null);

  const [loading, setLoading] = useState(false);

  const clearState = () => {
    setFullName("");
    setPhoneNumber("");
    setEmail("");
    setPanKYC("");
    setOpenSnackbar(false);
    setErrorMessage("");
  };

  const [fullNameRequired, setFullNameRequired] = useState(false);
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  // const [emailError, setEmailError] = useState("");

  // const [phoneLengthError, setPhoneLengthError] = useState("");

  const [panError, setPanError] = useState("");

  const validPanNumber = /^[A-Z]{5}\d{4}[A-Z]$/;

  const handlePanChange = (e) => {
    const newPanInput = e.target.value?.slice(0, 10)?.toUpperCase();
    setPanKYC(newPanInput);
    if (!validPanNumber.test(newPanInput)) {
      setPanError("Invalid PAN card number");
    } else {
      setPanError("");
    }
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const prod = 18618;
  // const staging = 17914;

  const joinWaitList = async () => {
    if (!fullName || !phoneNumber || !email) {
      !fullName && setFullNameRequired(true);
      !phoneNumber && setPhoneNumberRequired(true);
      !email && setEmailRequired(true);
      return;
    }

    // if (!validateEmail(email)) {
    //   setEmailError("Invalid email format");
    //   return;
    // }
    // if (phoneNumber.length !== 10) {
    //   setPhoneLengthError(
    //     phoneNumber.length > 10
    //       ? "Phone number should not exceed 10 digits"
    //       : "Phone number should be exactly 10 digits"
    //   );
    //   return;
    // } else {
    //   setPhoneLengthError("");
    // }

    setLoading(true);
    let signupParams = {
      first_name: fullName,
      phone: phoneNumber ? `+${phoneNumber}` : "",
      email: email,
      waitlist_id: prod,
    };

    if (panKYC) {
      signupParams.metadata = {
        pan_number: panKYC,
      };
    }

    try {
      const res = await axios.post(
        `https://api.getwaitlist.com/api/v1/signup`,
        signupParams
      );
      setSuccessStatus(res?.status);
      setLoading(false);

      clearState();
    } catch (e) {
      console.log("Error while signing up:", e.message);
      setLoading(false);
      setErrorMessage(
        e?.response?.data?.error_string
          ? e?.response?.data?.error_string
          : "Something went wrong"
      );
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      {successStatus === 200 ? (
        <SubmitSuccess />
      ) : (
        <div className="join-wait-list-main flex flex-col gap-3">
          <Snackbar
            variant="soft"
            color={"danger"}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
            sx={{
              fontSize: "15px",
              width: "90%",
              padding: "12px 15px",
              marginTop: "6px",
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={10000}
            endDecorator={
              <Button
                onClick={() => setOpenSnackbar(false)}
                size="sm"
                variant="soft"
                color={"danger"}
              >
                <CancelIcon />
              </Button>
            }
          >
            {errorMessage}
          </Snackbar>
          <div className="flex gap-3 justify-center items-center">
            <img src={Prosper} alt="img" />
            <img src={Line} alt="img" />
            <img src={Save} alt="img" />
          </div>

          <div className="paddingtext">
            <h2 className="text-[22px] text-[#1F2937] text-center font-semibold">
              Start Your Investment Journey Today
            </h2>
          </div>

          <div className="form-join-waitlist">
            <div className="field-with-label-and-input">
              <label className="text-[15px]">
                Your full name<span className=" text-[#090A0A]">*</span>
              </label>
              <input
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setFullNameRequired(false);
                }}
                type="text"
                placeholder="Full name"
                className="input-join"
              />
              {fullNameRequired && (
                <div className="text-sm text-red-500 -mt-1">
                  Full Name is required
                </div>
              )}
            </div>
            <div className="w-[100%] field-with-label-and-input">
              <label className="text-[15px]">
                Phone number<span className=" text-[#090A0A]">*</span>
              </label>
              <PhoneInput
                containerStyle={{
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
                inputStyle={{
                  width: "100%",
                  fontSize: "18px",
                  height: "50px",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                dropdownStyle={{ fontSize: "14px", width: "92vw" }}
                buttonStyle={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                country={"ae"}
                preferredCountries={["ae", "in", "us", "sg", "gb"]}
                value={phoneNumber}
                onChange={(phone) => {
                  setPhoneNumber(phone);
                  setPhoneNumberRequired(false);
                }}
                placeholder="Mobile number"
                enableSearch={true}
              />
              {phoneNumberRequired && (
                <div className="text-sm text-red-500 -mt-1">
                  Phone number is required
                </div>
              )}
            </div>

            <div className="field-with-label-and-input">
              <label className="text-[15px]">
                Email<span className="text-[#090A0A]">*</span>
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailRequired(false);
                }}
                type="text"
                placeholder="Email"
                className="input-join"
              />
              {emailRequired && (
                <div className="text-sm text-red-500 -mt-1">
                  Email is required
                </div>
              )}
              {/* {emailError && (
          <div className="text-sm text-red-500 -mt-2">{emailError}</div>
        )} */}
            </div>
            <div className="field-with-label-and-input">
              <label className="text-[15px]">PAN</label>
              <input
                onChange={handlePanChange}
                value={panKYC}
                type="text"
                placeholder="PAN number"
                className="input-join"
              />
              {panError && (
                <div className="text-sm text-red-500 -mt-1">{panError}</div>
              )}
              <p className="text-[#72777A] text-xs">
                We need your PAN to check your KYC and eligibility for <br />{" "}
                investing in Indian mutual funds.
              </p>

              <p className="text-black mt-2">
                Don’t have a PAN?&nbsp;&nbsp;
                <span
                  onClick={handleClick}
                  className="text-[#0060FF] font-semibold"
                >
                  Reach out to us
                </span>
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <button
              className={`join-btn-waitlist rounded-full transition-all ${
                fullName && email && phoneNumber
                  ? "bg-[#0060FF] text-white"
                  : "bg-[#EBEBE4] text-black"
              }`}
              onClick={() => joinWaitList()}
            >
              {loading ? (
                <div className="circular-progress"></div>
              ) : (
                "Join waitlist!"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinWaitListForm;
