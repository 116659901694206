import React from "react";
import "./SetupAccount.css";
import Account from "../../Assets/account.svg";
import MoneyBasket from "../../Assets/money-basket.svg";
import UPI from "../../Assets/upi.svg";
import MobileImage from "../../Assets/mobile.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SetupAccount = () => {
  const data = [
    {
      id: 1,
      img: Account,
      title: "Setup your account",
      content:
        "Create your account, complete your nominee profile and validate KYC.",
    },
    {
      id: 2,
      img: MoneyBasket,
      title: "Pick your basket",
      content: "Choose a tailored basket designed to protect downside.",
    },
    {
      id: 3,
      img: UPI,
      title: "Set Autopay",
      content: "Complete the payment & set autopay for recurring investments.",
    },
  ];

   const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  return (
    <div className="bg-[#F6F9FC] setup-main flex flex-col gap-10">
      <div className="flex flex-col justify-center items-center gap-10">
        <h2 className="text-xl font-semibold text-center">
          Get started with prospr with 3 easy steps
        </h2>
        <img src={MobileImage} alt="mobile-image" />
      </div>

    
        <div className="slide-main">
        <Slider {...settings} className="slider-class px-2">
            {data.map((item) => (
              <div className="slide" key={item.id}>
                <img src={item.img} alt="setup-icon" />
                <h2 className="text-xl font-semibold mt-4">{item.title}</h2>
                <p className="text-[16px] text-[#4B5563] mt-4">
                  {item.content}
                </p>
              </div>
            ))}
          </Slider>
        </div>
       
    </div>
  );
};

export default SetupAccount;
