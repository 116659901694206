import React from "react";
import Tick from "../../../Assets/tick.svg";
import { useNavigate } from "react-router-dom";

const SubmitSuccess = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-[100%] mt-[180px]">
        <div className="flex flex-col justify-center items-center gap-5">
          <img src={Tick} alt="tick-img" className="w-[110px] h-[110px]" />
          <h1 className="font-semibold text-[22px] text-center text-[#171716]">
            Thanks for joining the waitlist.
          </h1>
          <p className="mt-1 text-[#4B5563] text-[16px]  text-center">
            Your details have been received.
            <br /> Our team will reach out to you shortly.
          </p>
          <button
            onClick={() => handleClose()}
            className=" mt-3 bg-[#0060FF] text-white w-[150px] text-[18px] py-[8px] rounded-full"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmitSuccess;
