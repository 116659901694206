import React from "react";
import "./Footer.css";
import FooterSpring from "../../../Assets/footerSpring.svg";
import Prospr from "../../../Assets/prospr.svg";
import SaveInvestAnywhere from "../../../Assets/Save & Invest, Anywhere..svg";
import LineFooter from "../../../Assets/Line 50.svg";
import LineHorizontal from "../../../Assets/horiLine.svg";
import Whatsapp from "../../../Assets/whatsapp.svg";

const Footer = () => {
  const phoneNumber = "+919790742684";
  const message = "Hi";

  const handleClick = (e) => {
    e.preventDefault();
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="footer-main flex flex-col gap-10 px-5">
      <div className="flex justify-center items-center gap-3">
        <img src={Prospr} alt="" />
        <img src={LineHorizontal} alt="" />
        <img src={SaveInvestAnywhere} al />
      </div>

      <img src={LineFooter} alt="line" />

      <div className="flex flex-col items-center gap-5  z-10">
        <a href="#" onClick={handleClick}>
          <h2 className="text-base text-white flex flex-row items-center gap-2">
            Contact
            <img
              className="social-media-icon-1"
              src={Whatsapp}
              alt="Whatsapp-img"
            />
          </h2>
        </a>
        {/* <h2 className="text-base text-white">Terms & Conditions</h2>
        <h2 className="text-base text-white">Privacy Policy</h2> */}
        <h2 className="text-base text-[#9CA3AF]">
          © 2024 prospr. All rights reserved.
        </h2>
      </div>
      <img
        src={FooterSpring}
        alt="footer-bg"
        className="footer-spring-design mix-blend-soft-light"
      />
    </div>
  );
};

export default Footer;
