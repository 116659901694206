import React, { useEffect, useState } from "react";
import "./InvestCalculation.css";
import { Select, Input, Option } from "@mui/joy";
import ChartCalulation from "./ChartCalulation/ChartCalulation";
import CalculationTable from "./CalculationTable/CalculationTable";

const InvestCalculation = () => {
  const [currency, setCurrency] = useState("aed");

  const [amounts, setAmounts] = useState([1000, 2000, 3000]);
  

  const [selectedAmount, setSelectedAmount] = useState(2000);

  const [duration, setDuration] = useState(5);

  const [riskSelection, setRiskSelection] = useState(16);

  const [M, setM] = useState(null);
  console.log(M);

  const handleChange = (e, value) => {
    setCurrency(value);
    if (value === "aed") {
      setAmounts([1000, 2000, 3000]);
      setSelectedAmount(1000);
    } else if (value === "usd") {
      setAmounts([300, 600, 900]);
      setSelectedAmount(300);
    } else if (value === "inr") {
      setAmounts([20000, 40000, 60000]);
      setSelectedAmount(20000);
    }
  };

  const calculateM = (P, n, i) => {
    console.log("after", i);

    console.log("inside funciton", P, n, i);
    // M = P * ({[1+i]^{12}-1}/i)*(1+i)
    const part1 = [1 + i] ** n - 1;
    console.log("part1", part1);

    const part2 = (part1 / i) * (1 + i);
    console.log("part2", part2);

    return P * part2;
  };

  const handleCalculateButton = () => {
    let P = selectedAmount;
    let n = duration * 12;
    console.log("n", n);
    let rateOfInterest = Number(riskSelection) / 100;
    console.log("rate", rateOfInterest);

    let i = rateOfInterest / 12;

    const M = calculateM(P, n, i);
    const roundedM = Math.round(M);    
    setM(roundedM.toLocaleString("en-IN"));
  };

  useEffect(() => {
    handleCalculateButton();
  }, [selectedAmount, duration, riskSelection]);

  return (
    <div className="invest-calculation-main">
      <CalculationTable currency={currency} selectedAmount={selectedAmount} />

      <div className="flex flex-col justify-center items-center gap-5">
        <h2 className="text-2xl text-[#1F2937] font-semibold text-center leading-loose tracking-wider">
          The Secret to Wealth: <br />
          Monthly SIP <br /> <p className="text-[#0060FF]">Just Get Started</p>
        </h2>

        <div
          className="flex flex-col gap-5 mt-4"
          style={{ width: "fit-content" }}
        >
          <div
            className="flex items-end gap-4"
            style={{ width: "fit-content" }}
          >
            <h2 className="-tracking-tighter text-xl font-semibold">Invest</h2>
            <span className="input-currency-main mb-1">
              <Select
                indicator={false}
                className="input-currency"
                defaultValue="aed"
                onChange={handleChange}
                value={currency}
              >
                <Option className="" value="aed">
                  AED
                </Option>
                <Option className="" value="usd">
                  USD
                </Option>
                <Option className="" value="inr">
                  INR
                </Option>
              </Select>
            </span>
            <span className="input-currency-money-main mb-1">
              <Select
                indicator={false}
                className="input-money"
                value={selectedAmount}
                onChange={(e, value) => setSelectedAmount(value)}
              >
                {amounts.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </span>
          </div>

          <div
            className="flex items-end gap-4"
            style={{ width: "fit-content" }}
          >
            <h2 className="-tracking-tighter text-xl font-semibold">
              monthly in Nifty Index Fund for
            </h2>
          </div>

          <div
            className="flex items-end gap-4"
            style={{ width: "fit-content" }}
          >
            <h2 className="-tracking-tighter text-xl font-semibold">
              the next
            </h2>

            <span className="input-currency-duration-main mb-1">
              <Select
                indicator={false}
                className="input-duration"
                value={duration}
                onChange={(e, value) => {
                  setDuration(value);
                }}
              >
                <Option value={3}>3</Option>
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
              </Select>
            </span>
            <h2 className="-tracking-tighter text-xl font-semibold">years.</h2>
          </div>
          {/* <button
            onClick={() => handleCalculateButton()}
            className="calculate-button"
          >
            Calculate
          </button> */}
        </div>
      </div>

      <ChartCalulation
        M={M}
        selectedAmount={selectedAmount}
        duration={duration}
        riskSelection={riskSelection}
        currency={currency}
      />
    </div>
  );
};

export default InvestCalculation;
