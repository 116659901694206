import React from "react";
import "./Home.css";

import dotBall from "../../Assets/moneyHome.svg";
import Navbar from "./Navbar/Navbar";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="home flex flex-col justify-center">
      <Navbar />

      <img className="dotBall-design" src={dotBall} alt="backgroung-img" />
      <div className="flex  flex-col justify-center items-center  ">
        <div className="text-center mt-16 container-home">
          <h2 className="texts-see text-center font-bold text-white leading-tight">
            Invest In
          </h2>

          <div className="cubespinner animate-flipper pb-2">
            <div className="face1 font-bold w-[100%]  texts-see   text-[#4ADE80]">
              Third Largest
            </div>
            <div className="face2 font-bold w-[100%] texts-see   text-[#4ADE80]">
              Fast Growing
            </div>
            <div className="face3 font-bold w-[100%] texts-see   text-[#4ADE80]">
              Diverse
            </div>
          </div>
          <h2 className="texts-see text-center font-bold text-white leading-tight anothertext-margin">
            Economy
          </h2>
        </div>
        <h2 className="text-subheading text-center text-[#D1D5DB] mt-[30px] ">
          For a Better Life Tomorrow!
        </h2>

        <Link to={"/join-waitlist"} className="btn-join mx-auto  rounded-full">
          Join waitlist!
        </Link>
      </div>
    </div>
  );
};

export default Home;
