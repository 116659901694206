import React, { useRef, useState } from "react";
import ArrowUp from "../../Assets/arrow-down.svg";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const data = [
    {
      title: "What is prospr?",
      content:
        "Prospr is a cross-border platform for savings and investments. We advise and enable execution of mutual fund transactions through our platform. We are a AMFI registered Mutual Fund Distributor and regulated by AMFI and SEBI.",
    },
    {
      title: "How will prospr help me?",
      content:
        "We enable discovery of investments and transaction execution on our platform. Besides, we help in KYC and other compliances that are required to invest in respective geographies.",
    },
    {
      title: "Which are the major corridors covered by prospr?",
      content:
        "Prospr is a global company and aspires to serve in all major corridors. Currently, we are serving GCC and India corridor. ",
    },
    {
      title: "Is my money safe?",
      content:
        "Prospr do not handle money movement. Your money is safe and secured with banks and asset management companies regulated by Reserve Bank of India and Securities Exchange Board of India (SEBI).",
    },
    {
      title: "Is there a lock-in period?",
      content:
        "Prospr recommends investments with high marketability and liquidity. We only recommend investments that are traded on stock exchanges with no lock-in period. You can redeem investments at any point in time. You are always in control of your funds in bank and investments.",
    },
    {
      title: "Who manages the investments and portfolio?",
      content:
        "Investments and portfolio are managed and supervised by professional fund managers, who are qualified and has years of experience.",
    },
    {
      title: "What is SBI Nifty Index Fund?",
      content:
        "SBI Nifty Index Fund is a Exchange Traded Funded floated and operated by SBI Mutual Fund. The fund will invest in stocks comprising the Nifty 50 Index in the same proportion as in the index with the objective of achieving returns equivalent to the Nifty 50 Index.",
    },
    {
      title: "What is Nifty 50?",
      content:
        "Nifty 50 is an Index of top 50 companies of India by market capitalisation. National Stock Exchange of India manages and supervises the Index on a periodic basis. Currently, the Index has companies such as Reliance Limited, Infosys Limited, HDFC Bank, State Bank of India, Tata Steel, Tata Motors, etc.",
    },
    {
      title: "What is SBI Mutual Fund?",
      content:
        "SBI Mutual Fund is an Asset Management Company regulated by Securities Exchange Board of India (SEBI). SBI Mutual Fund is a subsidiary of the State Bank of India. The Company manages assets of more than Rs. 7 lakh crore.",
    },
    {
      title: "Haven't Found Your Answer?",
      content:
        "If you have any further questions, our friendly customer support team is always happy to help! Simply reach out to us through the app or website.",
    },
  ];
  return (
    <div className="mx-auto w-[100%] px-[20px] py-[50px] flex flex-col gap-3 ">
      <h2 className="text-2xl font-semibold text-center mb-6">
        Frequently asked questions
      </h2>

      {data.map((item, index) => (
        <div
          key={index}
          className="bg-[#F6F9FC] py-4 px-4 rounded-xl border-b-2 border-[#C8E5E1]"
        >
          <div
            className="flex justify-between items-center w-[100%]"
            onClick={() => toggle(index)}
          >
            <h1 className="w-[80%] cursor-pointer text-[#000000] font-semibold text-[16px]">
              {item.title}
            </h1>

            <h1 className="w-[20%]">
              <img src={ArrowUp} className={`float-end transition-transform `} alt="up-arrow" />
            </h1>
          </div>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className="transition-all overflow-hidden duration-500 ease-in-out"
            style={{
              maxHeight: openIndex === index ? contentRefs.current[index]?.scrollHeight : 0,
            }}
          >
            <p className="py-2 pe-10 text-[#374151] text-[15px] leading-loose ">
              {item.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
