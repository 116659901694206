import React from "react";
import Home from "../Components/Home/Home";
import ProsprEmpower from "../Components/ProsprEmpower/ProsprEmpower";
import SetupAccount from "../Components/SetupAccount/SetupAccount";
import GetInTouch from "../Components/GetInTouch/GetInTouch";
import Queries from "../Components/Queries/Queries";
import Footer from "../Components/Home/Footer/Footer";
import InvestCalculation from "../Components/InvestCalculation/InvestCalculation";
import Faq from "../Components/Faq/Faq";
import InvestInCompany from "../Components/InvestInCompany/InvestInCompany";

const Main = () => {
  return (
    <div>
      <div className="App relative">
        <Home />
        <InvestInCompany/>
        <ProsprEmpower />
        {/* <SetupAccount /> */}
        <InvestCalculation />
        <GetInTouch />
        <Faq/>
        {/* <Queries /> */}
        <Footer />
      </div>
    </div>
  );
};

export default Main;
