import React from "react";
import "./ProsprEmpower.css";

import Currencychange from "../../Assets/currency-exchange-three.png";
import Wealth from "../../Assets/wealth-two.png";
import Secured from "../../Assets/secured-wallet-one.png";
import SBI from "../../Assets/sbi.svg";
import RESERVE from "../../Assets/reserve.svg";
import AMFI from "../../Assets/amfi.svg";

const ProsprEmpower = () => {
  const prosprEmpowerData = [
    {
      img: Currencychange,
      heading: (
        <span>
          Savings &amp; Investments
          <br />
          across borders
        </span>
      ),
      paragraph: "We simplify managing finances across borders",
    },
    {
      img: Wealth,
      heading: (
        <span>
          Wealth creation
          <br />
          on autopilot
        </span>
      ),
      paragraph: "We make savings & investments automated.",
    },
    {
      img: Secured,
      heading: (
        <span>
          Your money is
          <br />
          Safe and Secured
        </span>
      ),
      paragraph: "It is safe with regulated banks & financial institutions",
      onlySecured1: RESERVE,
      onlySecured2: AMFI,
      onlySecured3: SBI,
    },
  ];

  console.log("asdsds", prosprEmpowerData[2]);

  return (
    <div className="prospr-Empower bg-[#F9FAFB]">
      <div className="">
        <h2 className="text-2xl font-semibold text-center px-2">
          <span className="text-[#0060FF] tracking-widest">prospr</span>{" "}
          empowers busy expats and immigrants to save & invest effortlessly
        </h2>
      </div>

      <div className="prospr-container-main">
        {prosprEmpowerData?.map((data, index) => (
          <div key={index} className="prospr-container">
            <div className="prospr-img-container mb-2">
              <img src={data?.img} className="img-propser-icons" />
            </div>
            {data?.onlySecured1 && index === 2 && (
              <div className="flex flex-row items-center gap-3">
                <div className="w-[32px] h-[32px]">
                  <img
                    src={data?.onlySecured1}
                    alt="one"
                    className="w-[100%] h-[100%]"
                  />
                </div>
                <div className="w-[40px] h-[40px]">
                  <img
                    src={data?.onlySecured2}
                    alt="two"
                    className="w-[100%] h-[100%]"
                  />
                </div>
                <div className="w-[50px] h-[45px]">
                  <img
                    src={data?.onlySecured3}
                    alt="three"
                    className="w-[100%] h-[100%]"
                  />
                </div>
              </div>
            )}
            <h2 className="font-semibold text-[19px] tracking-wide">
              {data?.heading}
            </h2>

            <h2 className="text-[17px]  text-[#4B5563] font-[500]">
              {data?.paragraph}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProsprEmpower;
