import React from "react";
import "./InvestInCompany.css";
import One1 from "../../Assets/One1.svg";
import One2 from "../../Assets/One2.svg";
import One3 from "../../Assets/One3.svg";
import One4 from "../../Assets/One4.svg";

import Two1 from "../../Assets/Two1.svg";
import Two2 from "../../Assets/Two2.svg";
import Two3 from "../../Assets/Two3.svg";
import Two4 from "../../Assets/Two4.svg";

import Three1 from "../../Assets/Three1.svg";
import Three2 from "../../Assets/Three2.svg";
import Three3 from "../../Assets/Three3.svg";
import Three4 from "../../Assets/Three4.svg";

import Four1 from "../../Assets/Four1.svg";
import Four2 from "../../Assets/Four2.svg";
import Four3 from "../../Assets/Four3.svg";
import Four4 from "../../Assets/Four4.svg";

import Five1 from "../../Assets/Five1.svg";
import Five2 from "../../Assets/Five2.svg";
import Five3 from "../../Assets/Five3.svg";
import Five4 from "../../Assets/Five4.svg";

import IndianFlag from "../../Assets/IndianFlag.png";
import { Link } from "react-router-dom";

const InvestInCompany = () => {
  return (
    <div className="invest-in-companies-main">
      <div className="">
        <div className="w-[50px] h-[50px] mx-auto">
          <img
            src={IndianFlag}
            alt="indian-flag"
            className="bg-transparent w-[100%] h-[100%]"
          />
        </div>
        <h2 className="text-2xl tracking-wider font-semibold text-center mt-2">
          Invest in India’s top <br /> 50 companies in one click
        </h2>
      </div>

      <div className="flex flex-col gap-5 mt-10">
        <div className="grid grid-cols-4 gap-3">
          <div className="flex justify-center items-center ">
            <img src={One1} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={One2} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={One3} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center ">
            <img src={One4} alt="logo" className="h-[65px] w-[65px]" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3">
          <div className="flex justify-center items-center ">
            <img src={Two1} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Two2} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Two3} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center ">
            <img src={Two4} alt="logo" className="h-[65px] w-[65px]" />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-3">
          <div className="flex justify-center items-center ">
            <img src={Three1} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Three2} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Three3} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center ">
            <img src={Three4} alt="logo" className="h-[65px] w-[65px]" />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-3">
          <div className="flex justify-center items-center ">
            <img src={Four1} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Four2} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Four3} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center ">
            <img src={Four4} alt="logo" className="h-[65px] w-[65px]" />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-3">
          <div className="flex justify-center items-center ">
            <img src={Five1} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Five2} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Five3} alt="logo" className="h-[65px] w-[65px]" />
          </div>
          <div className="flex justify-center items-center ">
            <img src={Five4} alt="logo" className="h-[65px] w-[65px]" />
          </div>
        </div>
      </div>

      <Link to={"/join-waitlist"} className="flex justify-center ">
        <button className="btn-invest-invested rounded-full ">
          Invest Now!
        </button>
      </Link>
    </div>
  );
};

export default InvestInCompany;
